import * as React from "react";
import styled from "styled-components";
import logo from "../assets/shield-logo.png";
import {fonts} from "../styles";

const SBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & span {
    color: #147998;
    font-weight: ${fonts.weight.bold};
    font-size: ${fonts.size.h5};
    margin-left: 12px;
  }
`;

const SBanner = styled.div`
  width: 45px;
  height: 45px;
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const Banner = () => (
    <SBannerWrapper>
        <SBanner/>
        <span>{`OHN`}</span>
    </SBannerWrapper>
);

export default Banner;
