import * as React from "react";
import {significantDigits} from "../helpers/significantDigits";
import {IAppState} from "../App";
import styled from "styled-components";
import logo from "../assets/shield-logo.png";

const SWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 1em 0 0;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
  background-image: linear-gradient(180deg, #fafeff 0%, #effaff 100%);
  border-radius: 4px;
  box-shadow: 0 0 30px #eee;
`;

const SLogo = styled.div`
  width: 45px;
  height: 45px;
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const STitle = styled.h3`
  font-size: 20px;
  padding-top: 0;
  margin: 1em 0 0.5em;
`;

const SRewardTitle = styled.h4`
  font-size: 18px;
  padding-top: 0;
  margin: 0.5em 0;
`;

const SRewardShare = styled.div`
  font-size: 20px;
  margin: 0.5em 0;
  font-weight: 700;
`;

const SDescription = styled.div`
  font-weight: 500;
  margin: 0.5em 0;
  font-style: italic;
  font-size: 14px;
`;

const AccountAssets = (props: IAppState) => {
    if (!props.assets) {
        return null;
    }

    let share = Number(props.assets.claimableBNB )/  Number(props.assets.totalRewardPool)

    console.log("qwee",share,"13rrr",Number(props.assets.totalRewardPool))
    return (
        <SWrapper>
            <SLogo/>
            <STitle>OHN</STitle>
            <SRewardTitle>Your reward share</SRewardTitle>
            <SRewardShare>{isNaN(share)?0: significantDigits(share * 100, 2)}%</SRewardShare>
            <SDescription>The more OHN tokens you own, the higher share of reward pool you'll receive!</SDescription>
        </SWrapper>
    );
};

export default AccountAssets;
