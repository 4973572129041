import * as React from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import Banner from "./Banner";
import {getChainData} from "../helpers/utilities";
import {transitions} from "../styles";
import logo from "../assets/shield-logo.png";
import Button from "./Button";

const SHeader = styled.div`
  margin-top: -1px;
  margin-bottom: 1px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const SBanner = styled.div`
  width: 45px;
  height: 45px;
  background: url(${logo}) no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 0.5em;
`;

const SActiveAccount = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 500;
`;

const SActiveChain = styled(SActiveAccount)`
  text-align: left;
  & p {
    font-size: 0.8em;
    margin: 0;
    padding: 0;
  }
  & p:nth-child(2) {
    font-weight: bold;
  }
`;

interface IHeaderStyle {
    connected: boolean;
}

const SAddress = styled.p<IHeaderStyle>`
  transition: ${transitions.base};
  font-weight: bold;
  margin: ${({connected}) => (connected ? "-2px auto 0.7em" : "0")};
`;

const SDisconnect = styled(Button)<IHeaderStyle>`
  transition: ${transitions.button};
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 20px;
  opacity: 0.7;
  cursor: pointer;
  border-radius: 8px;
  margin: 12px 0;

  opacity: ${({connected}) => (connected ? 1 : 0)};
  visibility: ${({connected}) => (connected ? "visible" : "hidden")};
  pointer-events: ${({connected}) => (connected ? "auto" : "none")};

  &:hover {
    transform: translateY(-1px);
    opacity: 0.5;
  }
`;

interface IHeaderProps {
    killSession: () => void;
    connected: boolean;
    address: string;
    chainId?: number;
}

const Header = (props: IHeaderProps) => {
    const {connected, address, chainId, killSession} = props;

    let chainData, error = false;

    try {
        chainData = chainId ? getChainData(chainId) : null;
    } catch (e) {
        error = true;
    }

    return (
        <SHeader {...props}>
            {!error && connected && chainData ? (
                <SActiveChain>
                    <SBanner/>
                    <div style={{color:"#fff"}}>
                        <p>{`Connected to`}</p>
                        <p>{chainData.name}</p>
                    </div>
                </SActiveChain>
            ) : (
                <Banner/>
            )}
            {!error && address && (
                <SActiveAccount style={{color:"#fff"}}>
                    <SAddress connected={connected}>{address}</SAddress>
                    <SDisconnect connected={connected} onClick={killSession}>
                        {"Disconnect"}
                    </SDisconnect>
                </SActiveAccount>
            )}
        </SHeader>
    );
};

Header.propTypes = {
    killSession: PropTypes.func.isRequired,
    address: PropTypes.string,
};

export default Header;
