import shieldContractABI from '../assets/contractABI.json'

export interface ContractSetup {
  address: string;
  abi: any[];
}

export const DAI_CONTRACT: { [chainId: number]: ContractSetup } = {
  1: {
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    abi: [
      {
        constant: true,
        inputs: [{ name: "src", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { name: "dst", type: "address" },
          { name: "wad", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
  },
};

export const SHIELD_CONTRACT: { [chainId: number]: ContractSetup } = {
  56: {
    address: "0xfa92152633fC4488D94a8ee70C11A6e332733bCD",
    abi: shieldContractABI,
  },
  97: {
    address: "0x34CF852A633d1014eb244241a24471E662a9a67b",
    abi: shieldContractABI,
  },
};
