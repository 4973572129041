import React from 'react';
import Modal from "../components/Modal";
import {SContainer} from "../styled-components/SContainer";
import {SModalContainer, SModalParagraph, SModalTitle} from "../styled-components/Modals";
import Loader from "../components/Loader";
import styled from "styled-components";

interface IProps {
    show: boolean;
    close: () => void;
    transactionHash?: string;
}

const SLink = styled.a`
  text-decoration: underline;
`;

const TransactionHashModal = (props: IProps) => {
    const {transactionHash} = props;

    const show = !!transactionHash && props.show;

    const link = `https://bscscan.com/tx/${transactionHash}`;

    return (
        <Modal show={show} toggleModal={props.close}>
            <SModalContainer>
                <SModalTitle>{`Please wait...`}</SModalTitle>
                <SContainer>
                    <Loader/>

                    <SModalParagraph>
                        {`The transaction hash: ${props.transactionHash}`}
                    </SModalParagraph>

                    <SModalParagraph>
                        {`You can view the transaction here: `}<SLink href={link} target="_blank">{"BSCScan ↗"}</SLink>
                    </SModalParagraph>
                </SContainer>
            </SModalContainer>
        </Modal>
    );
};

export default TransactionHashModal;
