import * as React from "react";
import {significantDigits} from "../helpers/significantDigits";
import {IAppState} from "../App";
import styled from "styled-components";
import {colors} from "../styles";
import Countdown from 'react-countdown';

const SWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin-top: 10vh;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

const SCollectableTitle = styled.h4`
  font-size: 18px;
  padding-top: 0;
  margin: 0.5em 0;
`;

const SForecast = styled.h4`
  font-size: 12px;
  color: rgb(${colors.lightBlue});
`;

const SDescription = styled.div`
  font-weight: 500;
  margin: 0.5em 0;
  font-style: italic;
  font-size: 14px;
`;

const SNextCollectDate = styled.div`
  font-weight: 500;
  margin: 0.5em 0;
`;

const SCountdownLabel = styled.div`
  font-weight: 500;
  margin: 0.5em 0;
  font-style: italic;
`;

const ClaimAssets = (props: IAppState) => {
        if (!props.assets) {
            return null;
        }

        let claimableBNB = props.web3.utils.fromWei(props.assets.claimableBNB, 'ether');
        const forecast = claimableBNB * 364;
        const date = props.assets.nextClaimDate;
        let claimable = date < new Date();

        const formattedDate = `${date.toLocaleString("en-US", {weekday: "long"})}, ${date.toLocaleString("en-US", {month: "long"})} ${date.getDate()}, ${date.getFullYear()} ${date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        })}`;

        if (claimableBNB === 0) {
            claimable = false;
        }

        if (claimableBNB > 1) {
            claimableBNB *= 0.8; // 20% tax on cashing out >1 BNB
        }

        const zeroBalance = claimableBNB === 0;

        return (
            <SWrapper>
                <SCollectableTitle>My collectable BNB: {significantDigits(claimableBNB, 4)} BNB</SCollectableTitle>

                {/*{!zeroBalance &&*/}
                {/*<SForecast>*Forecasted Annual BNB Rewards: {significantDigits(forecast, 4)} BNB</SForecast>*/}
                {/*}*/}

                <SDescription>
                    *Collectable amount depends on the size of the reward pool. It increases when someone buys or sells
                    tokens and decreases when holders are claiming the tokens.
                </SDescription>


                {!zeroBalance  && props.assets.nextClaimDate.getTime() > 0&&
                <SNextCollectDate>Next Collect Date: {formattedDate}</SNextCollectDate>
                }

                {!claimable &&
                <SCountdownLabel>
                    {"(claimable in "}
                    <Countdown daysInHours={true} date={date}/>
                    {")"}
                </SCountdownLabel>
                }
            </SWrapper>
        );
    }
;

export default ClaimAssets;
