import {SHIELD_CONTRACT} from  "../../constants/contracts";

export const web3ClaimBnb = async (address: string, chainId: number, web3: any) => {
    // try {
    const shieldContract = SHIELD_CONTRACT[chainId];
    const contract = new web3.eth.Contract(shieldContract.abi, shieldContract.address);
    const data = contract.methods.claimBNBReward();
    const gas = await data.estimateGas({from: address});
    const gasPrice = await web3.eth.getGasPrice();
    const tx = {
        from: address,
        to: SHIELD_CONTRACT[chainId].address,
        data: data.encodeABI(),
        gasPrice: web3.utils.toHex(gasPrice),
        gasLimit: web3.utils.toHex(gas + 10000),
    };
    return tx;
    // return new Promise((resolve, reject) => {
    //     web3.eth.sendTransaction(tx)
    //         .on("transactionHash", (hash: string) => {
    //             console.log("HASH", hash);
    //         })
    //         .on('confirmation', (confirmationNumber: number, receipt: any) => {
    //             resolve(receipt);
    //             this.setState({fetching: true});
    //         })
    //         .on('error', (err: any) => {
    //             console.log("2", err)
    //             reject(err)
    //         });
    // });

    // } catch (error) {
    //     console.error(error);
    //     await this.setState({fetching: false});
    // }
}
