import styled from "styled-components";

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  // margin-top: 30px;
`;

export {
    SModalContainer,
    SModalTitle,
    SModalParagraph
}
