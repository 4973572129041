import React from 'react';
import Modal from "../components/Modal";
import {SContainer} from "../styled-components/SContainer";
import {SModalContainer, SModalParagraph, SModalTitle} from "../styled-components/Modals";

interface IProps {
    show: boolean;
    close: () => void;
}

const IncorrectChainModal = (props: IProps) => {
    return (
        <Modal show={props.show} toggleModal={props.close}>
            <SModalContainer>
                <SModalTitle>{"Incorrect Chain"}</SModalTitle>
                <SContainer>
                    <SModalParagraph>
                        {"Please make sure to switch to the correct network. You should be using Binance Smart Chain (BSC) to use the OHN Dashboard."}
                    </SModalParagraph>
                </SContainer>
            </SModalContainer>
        </Modal>
    );
};

export default IncorrectChainModal;
