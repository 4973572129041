import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
import {convertHexToNumber} from "@walletconnect/utils";
import Web3Modal from "web3modal";
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import Loader from "./components/Loader";
import ConnectButton from "./components/ConnectButton";
import {getChainData} from "./helpers/utilities";
import {fonts} from "./styles";
import {SContainer} from "./styled-components/SContainer";
import IncorrectChainModal from "./modals/IncorrectChainModal";
import {web3ClaimBnb} from "./helpers/shield/claimBNB";
import {web3GetNextClaimDate} from "./helpers/shield/getNextClaimDate";
import {web3CalculateBNBReward} from "./helpers/shield/calculateBNBReward";
import TransactionHashModal from "./modals/TransactionHashModal";
import TransactionCompleteModal from "./modals/TransactionCompleteModal";
import {web3GetTotalRewardPool} from "./helpers/shield/getTotalRewardPool";
import AccountAssets from "./components/AccountAssets";
import ClaimAssets from "./components/ClaimAssets";


const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column)`
  height: 600px;
`;

const SBalances = styled(SLanding)`
  height: 100%;
`;

const ClaimBtn = styled(Button)`
  border-radius: 8px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
`;

enum Modals {
    None,
    IncorrectChain,
    TransactionInProgress,
    TransactionComplete,
}

export interface IAppAssetsData {
    claimableBNB: number;
    nextClaimDate: Date;
    totalRewardPool: number;
}

export interface IAppState {
    fetching: boolean;
    address: string;
    web3: any;
    provider: any;
    connected: boolean;
    chainId: number;
    networkId?: number;
    assets?: IAppAssetsData;
    showModal: Modals;
    incorrectChain: boolean;
    pendingRequest: boolean;
    result: any | null;
    currentTransactionHash?: string;
    loadAssetInterval: any;
}

const INITIAL_STATE: IAppState = {
    fetching: false,
    address: "",
    web3: null,
    provider: null,
    connected: false,
    chainId: 56,
    networkId: undefined,
    assets: undefined,
    showModal: Modals.None,
    incorrectChain: false,
    pendingRequest: false,
    result: null,
    currentTransactionHash: undefined,
    loadAssetInterval: undefined,
};

function initWeb3(provider: any) {
    const web3: any = new Web3(provider);

    web3.eth.extend({
        methods: [
            {
                name: "chainId",
                call: "eth_chainId",
                outputFormatter: web3.utils.hexToNumber
            }
        ]
    });

    return web3;
}

class App extends React.Component<any, any> {
    // @ts-ignore
    public web3Modal: Web3Modal;
    public state: IAppState;
    // public contractAddress = "0x0e3eaf83ea93abe756690c62c72284943b96a6bc"; // HODL contract address
    // public contractAddress = "0x16ba34acdc8a432f91666bca54442f89bacb491d"; // Shield v1

    constructor(props: any) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };

        this.web3Modal = new Web3Modal({
            network: this.getNetwork(),
            cacheProvider: true,
            providerOptions: this.getProviderOptions()
        });
    }

    public componentDidMount() {
        if (this.web3Modal.cachedProvider) {
            this.onConnect();
        }
    }

    public onConnect = async () => {
        const provider = await this.web3Modal.connect();

        await this.subscribeProvider(provider);

        const web3: any = initWeb3(provider);

        const accounts = await web3.eth.getAccounts();

        const address = accounts[0];

        const networkId = await web3.eth.net.getId();

        const chainId = await web3.eth.chainId();

        await this.onChainOrNetworkChange(chainId, networkId);

        await this.setState({
            web3,
            provider,
            connected: true,
            address,
            chainId,
            networkId
        });

        await this.getAccountAssets(false);

        clearInterval(this.state.loadAssetInterval);
        await this.setState({
            loadAssetInterval: setInterval(this.getAccountAssets, 10 * 1000),
        })
    };

    public async onChainOrNetworkChange(chainId: string | number, networkId: number) {
        if (typeof chainId === "string") {
            chainId = convertHexToNumber(chainId);
        }
        try {
            getChainData(chainId);
        } catch (e) {
            await this.setState({showModal: Modals.IncorrectChain, incorrectChain: true});
            return
        }

        await this.setState({chainId, networkId, incorrectChain: false});
    }

    public subscribeProvider = async (provider: any) => {
        if (!provider.on) {
            return;
        }
        provider.on("close", () => this.resetApp());
        provider.on("accountsChanged", async (accounts: string[]) => {
            await this.setState({address: accounts[0]});
            await this.getAccountAssets();
        });
        provider.on("chainChanged", async (chainId: string | number) => {
            const {web3} = this.state;
            const networkId = await web3?.eth.net.getId();
            await this.onChainOrNetworkChange(chainId, networkId);
            await this.getAccountAssets();
        });
    };

    public getNetwork = () => {
        if (typeof this.state.chainId === "undefined") {
            return undefined;
        }
        return getChainData(this.state.chainId).network;
    };

    public getProviderOptions = () => {
        const providerOptions = {
            // trust: {
            //   package: providers.TRUST
            // },
            // metamask: {
            //   package: providers.METAMASK
            // },
            // safepal: {
            //   package: providers.SAFE
            // },
            // walletconnect: {
            //   package: WalletConnectProvider,
            //   options: {
            //     infuraId: process.env.REACT_APP_INFURA_ID
            //   }
            // },
            // torus: {
            //   package: Torus
            // },
            // fortmatic: {
            //   package: Fortmatic,
            //   options: {
            //     key: process.env.REACT_APP_FORTMATIC_KEY
            //   }
            // },
            // authereum: {
            //   package: Authereum
            // },
            // bitski: {
            //   package: Bitski,
            //   options: {
            //     clientId: process.env.REACT_APP_BITSKI_CLIENT_ID,
            //     callbackUrl: window.location.href + "bitski-callback.html"
            //   }
            // }
        };
        return providerOptions;
    };

    public getAccountAssets = async (silent = true) => {
        if (this.state.incorrectChain) {
            return;
        }
        if (!silent) {
            this.setState({fetching: true});
        }
        const {address, chainId, web3} = this.state;
        try {
            const assets = {
                claimableBNB: await web3CalculateBNBReward(address, chainId, web3),
                nextClaimDate: await web3GetNextClaimDate(address, chainId, web3),
                totalRewardPool: await web3GetTotalRewardPool(chainId, web3),
            }
            if (!silent) {
                this.setState({fetching: false});
            }
            await this.setState({assets});
        } catch (error) {
            if (!silent) {
                this.setState({fetching: false});
            }
        }
    };

    public toggleModal = () => this.setState({showModal: this.state.showModal !== Modals.None});

    public resetApp = async () => {
        const {web3} = this.state;
        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        await this.web3Modal.clearCachedProvider();
        this.setState({...INITIAL_STATE});
    };

    public claimBnb = async () => {
        const {address, chainId} = this.state;

        if (!chainId) {
            await this.setState({showModal: Modals.IncorrectChain, incorrectChain: true});
            return;
        }

        this.setState({fetching: true});
        try {
            const tx = await web3ClaimBnb(address, chainId, this.state.web3);
            this.state.web3.eth.sendTransaction(tx)
                .on("transactionHash", async (hash: string) => {
                    await this.setState({currentTransactionHash: hash, showModal: Modals.TransactionInProgress})
                })
                .on('confirmation', async (confirmationNumber: number, receipt: any) => {
                    if (confirmationNumber === 0) {
                        await this.getAccountAssets(false);
                        await this.setState({fetching: false, showModal: Modals.TransactionComplete});
                    }
                })
                .on('error', (err: any) => {
                    console.log("2", err)
                });
        } catch (error) {
            console.error(error);
            await this.setState({fetching: false});
        }
    }


    public render = () => {
        const {
            assets,
            address,
            connected,
            chainId,
            fetching,
        } = this.state;

        let claimable = false;
        if (this.state.assets?.nextClaimDate && this.state.assets?.nextClaimDate < new Date()) {
            claimable = true;
        }

        return (
            <SLayout>
                <Column maxWidth={1000} spanHeight>
                    <Header
                        connected={connected}
                        address={address}
                        chainId={chainId}
                        killSession={this.resetApp}
                    />
                    <SContent>
                        {fetching || this.state.showModal === Modals.IncorrectChain ? (
                            <Column center>
                                <SContainer>
                                    <Loader/>
                                </SContainer>
                            </Column>
                        ) : !!assets ? (
                            <SBalances>
                                <AccountAssets {...this.state} />
                                <ClaimAssets {...this.state} />
                                {/*<AssetCards {...this.state} />*/}

                                <ClaimBtn disabled={!claimable} onClick={this.claimBnb}>
                                    Claim BNBs
                                </ClaimBtn>
                            </SBalances>
                        ) : (
                            <SLanding center>
                                <h3>{`OHN Dashboard`}</h3>
                                <ConnectButton onClick={this.onConnect}/>
                            </SLanding>
                        )}
                    </SContent>
                </Column>

                <IncorrectChainModal show={this.state.showModal === Modals.IncorrectChain} close={this.toggleModal}/>

                <TransactionHashModal
                    show={this.state.showModal === Modals.TransactionInProgress}
                    close={this.toggleModal}
                    transactionHash={this.state.currentTransactionHash}
                />

                <TransactionCompleteModal
                    show={this.state.showModal === Modals.TransactionComplete}
                    close={this.toggleModal}
                    transactionHash={this.state.currentTransactionHash}
                />
            </SLayout>
        );
    };
}

export default App;
